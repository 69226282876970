
import {
  DataTableColumn,
  FilterableDataTableColumn,
} from '@/models/DataTableColumn'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTableFilterSearchBar from './CUDataTableFilterSearchBar.vue'
import sidebar from '@/store/modules/sidebar'
import CUDataTableFilterInterface from '@/components/CUDataTableFilterInterface.vue'
import { TableViewFilterEvent } from '@/models/TableView'
import {
  FilterActionTypes,
  INVOICE_FILTER_ACTION,
  PAYSTUB_FILTER_ACTION,
  QUICKBOOKS_ACTIVITY_LOGS_FILTER_ACTION,
} from '@/models/FilterActionTypes'
import DriverPaySummaryInvoiceTabFilterActions from './DriverPaySummaryInvoiceTabFilterActions.vue'
import { VueConstructor } from 'vue/types/umd'
import DriverPaySummaryPaystubTabFilterActions from './DriverPaySummaryPaystubTabFilterAction.vue'
import QuickBooksActivityLogsFilterActions from './QuickBooksActivityLogsFilterActions.vue'

@Component({
  components: {
    CUDataTableFilterSearchBar,
    CUDataTableFilterInterface,
  },
})
export default class CUDataTableFiltersRow2 extends Vue {
  @Prop({
    required: true,
  })
  readonly columns!: DataTableColumn[]

  @Prop(Function) downloadMethod!: any

  @Prop({ required: false, default: undefined }) filterActionType:
    | FilterActionTypes
    | undefined

  @Prop({ required: false, default: undefined }) readonly searchPlaceholder!:
    | string
    | undefined

  get filterActionComponent(): VueConstructor | undefined {
    switch (this.filterActionType) {
      case INVOICE_FILTER_ACTION:
        return DriverPaySummaryInvoiceTabFilterActions
      case PAYSTUB_FILTER_ACTION:
        return DriverPaySummaryPaystubTabFilterActions
      case QUICKBOOKS_ACTIVITY_LOGS_FILTER_ACTION:
        return QuickBooksActivityLogsFilterActions
      default:
        return undefined
    }
  }

  get isSearchable(): boolean {
    return !!this.columns.filter((col) => col.filterBySearch).length
  }

  get filterableColumns(): FilterableDataTableColumn[] {
    return this.columns.filter(
      (col) => !col.filterHiddenOnTopBar && col.filterInterface
    )
  }

  updateSelectFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    if (sidebar.isOpen) {
      sidebar.pop()
    }

    this.$emit('update:filter', { column, event })
  }

  updateMultiSelectFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    if (sidebar.isOpen) {
      sidebar.pop()
    }

    this.$emit('update:filter', { column, event })
  }

  updateDateRangeFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    if (sidebar.isOpen) {
      sidebar.pop()
    }

    this.$emit('update:filter', { column, event })
  }

  updateSearchQuery(e): void {
    this.$emit('update:search', e)
  }

  clearFilters(): void {
    if (sidebar.isOpen) {
      sidebar.pop()
    }
    this.$refs['filter-search-bar']['clear']()
    this.$emit('clear-filters')
  }

  get areFilterButtonsVisible(): boolean {
    return !!this.filterableColumns.length
  }

  get areAllFiltersEmpty(): boolean {
    return !this.filterableColumns.some((col) => {
      if (Array.isArray(col.filterValue)) {
        return col.filterValue.length > 0
      }
      return !!col.filterValue
    })
  }
}
