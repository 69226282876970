
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
import driverPay from '@/services/driverpay'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { apiBaseUrl } from '@/utils/env'

@Component({})
export default class GeneratePayReportSidebar extends Vue {
  datePickerValue: string[] = []
  loading = false
  error = ''
  includeCancelledReservations = true

  handleDatePickerInput(e: string[]): void {
    this.datePickerValue = e as string[]
    this.error = ''
  }

  handleDatePickerClear(): void {
    this.datePickerValue = []
  }

  submit(): void {
    if (this.datePickerValue.length === 0) {
      this.error = 'Please select a date range'
      return
    }
    if (
      Array.isArray(this.datePickerValue) &&
      this.datePickerValue.length === 2
    ) {
      const [start, stop] = this.datePickerValue
      const url = `https://${apiBaseUrl(
        'pdf'
      )}/pdf/driver-pay-report?start=${start}&stop=${stop}&includeCancelledReservations=${
        this.includeCancelledReservations
      }&companyId=${auth.getCompanyId}`
      console.log(url)
      window.open(url, '_blank').focus()
      sidebar.pop()
    } else {
      console.error('Invalid date range selected')
    }
  }

  cancel(): void {
    sidebar.pop()
  }
}
