
import { EventBus } from '@/utils/eventBus'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class DriverPaySummaryPaystubTabFilterActions extends Vue {
  handleDownload(): void {
    EventBus.$emit('table-2:download', 'Pay Stubs')
  }
}
