
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import CUDataTable2 from '@/components/CUDataTable2.vue'
import { TableViewFilter, TableViewParameters } from '@/models/TableView'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { sort } from '@/utils/sort'
import { selectedColumn } from '@/utils/selectedColumn'
import { TableAction } from '@/models/TableAction'
import CUDataTableFiltersRow2 from '@/components/CUDataTableFiltersRow2.vue'
import { v4 } from 'uuid'
import { CurrentSort, SavedView } from '@/models/dto/SavedView'
import { saveAs } from 'file-saver'
import auth from '@/store/modules/auth'
import dayjs from 'dayjs'
import { FilterActionTypes } from '@/models/FilterActionTypes'
import deepClone from '@/utils/deepClone'
import sidebar from '@/store/modules/sidebar'
import GeneratePayReportSidebar from './GeneratePayReportSidebar.vue'

@Component({
  components: { CUDataTable2, CUDataTableFiltersRow2 },
})
export default class CUCollectionTable2 extends mixins(DateMixin) {
  @Prop({ type: Array, required: false, default: () => [] })
  columns!: DataTableColumn[]
  @Prop({ type: String, required: true }) collection!: string
  @Prop({ type: Array, required: false, default: () => [] })
  actions!: TableAction[]
  @Prop({ type: String, required: true }) itemKey!: string
  @Prop({ type: Boolean, default: false }) readonly showSavedViewMenu!: boolean
  @Prop(Function) fetchMethod!: any
  @Prop(Function) downloadMethod!: any
  @Prop({ type: String, required: false, default: 'download.csv' })
  downloadName!: string
  @Prop({ required: false, default: () => [] })
  initialFilters!: TableViewFilter[]
  @Prop({ type: Boolean, required: false, default: false })
  isFilterDialogOpen: boolean
  @Prop({ type: Boolean, required: false, default: false })
  customizeColumns: boolean
  @Prop({ type: String, required: false })
  noDataText!: string
  @Prop({ required: false, default: '26px' })
  titleSize: number | string
  @Prop({ type: Boolean, required: false, default: false })
  singleSelect: boolean
  @Prop({ required: false, default: undefined })
  filterActionType: FilterActionTypes | undefined
  @Prop({ required: false, default: 'Search' })
  searchPlaceholder: string

  @Prop({ required: false, default: () => [] }) initialSavedViews: SavedView[]
  @Prop({ required: false, default: () => ({}) }) readonly tableClasses: Record<
    string,
    string | boolean
  >
  @Prop({ required: false, default: false, type: Boolean })
  hideCollectionTitle!: boolean
  @Prop({ required: false, default: '' }) tableId: string
  @Prop({ required: false, default: () => ({}) }) headerClasses!: Record<
    string,
    string | boolean
  >
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  hideSelect!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  flat!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  filtersLoading!: boolean

  myColumns: DataTableColumn[] = []

  items: unknown[] = []
  loading = false
  serverItemsLength = 0
  debounce: ReturnType<typeof setTimeout> | null = null
  loadingDebounce: ReturnType<typeof setTimeout> | null = null
  initialFiltersSet = false
  firstLoadCompleted = false
  searchQuery = ''
  filters = []
  filtersByProp = {}
  sorts = sort()
  selectedColumns = selectedColumn()

  options: TableViewParameters = {
    page: 1,
    pageSize: 10,
  }

  @Watch('loading')
  onLoaded(): void {
    if (!this.loading) {
      EventBus.$emit('loaded', this.serverItemsLength)
    }
  }

  @Watch('serverItemsLength')
  serverItemsLengthUpdated(): void {
    EventBus.$emit('update:count', this.serverItemsLength)
  }

  @Watch('columns')
  onColumnsUpdate(): void {
    this.myColumns = deepClone(this.columns)
  }

  @Watch('tableFilters', { deep: true })
  onFiltersUpdate(): void {
    this.loadTableViewWithDebounce()
  }

  get titleFontSize(): string {
    if (typeof this.titleSize === 'number') {
      return String(this.titleSize)
    }
    return this.titleSize.replace(/[^\d]/gi, '')
  }

  get isFilterRowDisplayed(): boolean {
    if (this.downloadMethod) {
      return true
    }
    const filterableColumns = this.columns.filter(
      (col) => col.filterBySearch || col.filterInterface
    )
    if (filterableColumns.length) {
      return true
    }
    return false
  }

  get areInitialFiltersSet(): boolean {
    return this.initialFilters.length && !this.initialFiltersSet
  }

  get visibleColumns(): DataTableColumn[] {
    return this.columns.filter((column) => !column.hidden)
  }

  async handleDownload(collectionName: string): Promise<void> {
    if (this.collection !== collectionName) {
      return
    }

    const tz = auth?.getUser?.timeZone || dayjs.tz.guess()

    const params = {
      ...this.tableFilterParams,
      pageSize: this.options.pageSize,
      page: this.options.page,
      tz,
    }

    const date = this.formatLongDate(dayjs().toISOString(), { tz })
    const time = dayjs().tz(tz).format('h:mm:ss A z')
    const downloadName = `${this.downloadName} ${date} ${time}.csv`
    const downloadData = await this.downloadMethod(params)
    if (downloadData) {
      await saveAs(downloadData.data, downloadName)
    }
  }

  handleGeneratePayReportFromTripDates(): void {
    sidebar.push({
      component: GeneratePayReportSidebar,
      title: 'Generate Pay Report',
      props: { startDate: null, endDate: null },
    })
  }

  setTableViewPage(page: number): void {
    this.options.page = page
  }

  updateOptions({
    pageSize,
  }: {
    pageSize: number
    currentSort: CurrentSort
  }): void {
    this.options = {
      ...this.options,
      pageSize,
    }
    this.loadTableViewWithDebounce()
  }

  tableSorts = { sorts: null, sortDesc: null }

  handleUpdateOptions(e): void {
    this.options = e
    if (e?.sortBy?.length && e?.sortDesc?.length) {
      const columnValue = e.sortBy[0]
      const sortDesc = e.sortDesc[0]
      const column = this.columns.find((col) => col.value === columnValue)
      if (!column) {
        return
      }

      this.tableSorts.sorts = columnValue
      this.tableSorts.sortDesc = sortDesc
    } else {
      this.tableSorts = {
        sorts: null,
        sortDesc: null,
      }
    }

    if (this.firstLoadCompleted) {
      this.loadTableViewWithDebounce()
    }
  }

  loadTableViewWithDebounce(): void {
    if (this.loadingDebounce) {
      window.clearTimeout(this.loadingDebounce)
    }

    this.loadingDebounce = setTimeout(() => {
      this.load()
      this.firstLoadCompleted = true
    }, 500)
  }
  get tableFilterParams() {
    if (this.tableSorts?.sorts) {
      return {
        filters: this.tableFilters,
        ...this.tableSorts,
      }
    }
    return {
      filters: this.tableFilters,
    }
  }

  load(): void {
    if (this.areInitialFiltersSet) {
      return
    }

    this.loading = true
    const tz = !auth?.getUser?.timeZone ? dayjs.tz.guess() : null

    this.$nextTick(async () => {
      try {
        const response: { content: unknown[]; totalElements: number } =
          await this.fetchMethod({
            pageSize: this.options.pageSize,
            page: this.options.page,
            ...this.tableFilterParams,
            tz,
          })

        const { content, totalElements } = response
        this.serverItemsLength = totalElements // todo: make this dynamic
        this.$emit('update:count', totalElements)

        const items: unknown[] = content

        this.items = (items || []).map((item: any) => {
          const obj = { id: `${item[this.itemKey]}` }
          return Object.assign({}, item, obj)
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    })
  }

  updateRowsPerPage(e: number): void {
    if (Math.ceil(this.serverItemsLength / e) < this.options.page) {
      this.options.page = 1
    }
    this.options.pageSize = e
    this.loadTableViewWithDebounce()
  }

  get tableFilters(): any {
    const filterableColumnsWithValues = this.myColumns
      .filter((col) => col.filterInterface)
      .filter((col) =>
        Array.isArray(col.filterValue)
          ? col.filterValue.length > 0
          : !!col.filterValue
      )

    const selectFilters = filterableColumnsWithValues.reduce((acc, col) => {
      acc[col.filterProp as string] = col.filterValue
      return acc
    }, {})

    let searchFilters = {}

    if (this.searchQuery) {
      const searchableColumns = this.myColumns
        .filter((col) => col.filterBySearch)
        .map((col) => col.filterProp)
      searchFilters = searchableColumns.reduce((acc, col) => {
        acc[col as string] = this.searchQuery
        return acc
      }, {})
    }

    const filters = Object.assign({}, selectFilters, searchFilters)

    return filters
  }

  handleFilterUpdate(e): void {
    const { column, event } = e
    const matchingColumn = this.myColumns.find(
      (col) => col._t_id === column._t_id
    )
    if (!matchingColumn) {
      console.error('Error finding matching column to filter')
      return
    }
    this.$set(matchingColumn, 'filterValue', event)
  }

  handleSearchUpdate(e): void {
    this.searchQuery = e
  }

  handleClearFilters(): void {
    this.searchQuery = ''
    for (const col of this.myColumns) {
      if (Array.isArray(col.filterValue)) {
        this.$set(col, 'filterValue', [])
      } else {
        this.$set(col, 'filterValue', null)
      }
    }
  }

  mounted(): void {
    EventBus.$on('set-tableview-page', this.setTableViewPage)
    EventBus.$on('refresh-tableview', this.loadTableViewWithDebounce)
    EventBus.$on('table-views:set-options', this.updateOptions)
    EventBus.$on('table-2:download', this.handleDownload)
    EventBus.$on(
      'table-2:generate-pay-report-from-trip-dates',
      this.handleGeneratePayReportFromTripDates
    )

    this.myColumns = deepClone(this.columns)
  }

  beforeDestroy(): void {
    EventBus.$off('set-tableview-page', this.setTableViewPage)
    EventBus.$off('refresh-tableview', this.loadTableViewWithDebounce)
    EventBus.$off('table-views:set-options', this.updateOptions)
    EventBus.$off('table-2:download', this.handleDownload)
    EventBus.$off(
      'table-2:generate-pay-report-from-trip-dates',
      this.handleGeneratePayReportFromTripDates
    )
  }
}
