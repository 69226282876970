
import { EventBus } from '@/utils/eventBus'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class DriverPaySummaryInvoiceTabFilterActions extends Vue {
  handleReviewUnpaid(): void {
    EventBus.$emit('driver-pay:review-drafts')
  }

  handleDownload(): void {
    EventBus.$emit('table-2:download', 'Reservations')
  }

  handleGeneratePayReport(): void {
    EventBus.$emit('table-2:generate-pay-report-from-trip-dates')
  }
}
